
import Vue from "vue";
import { mdiChevronRight, mdiChevronLeft } from "@mdi/js";

export default Vue.extend({
  props: {
    hasNext: {
      type: Boolean,
      required: true
    },
    hasPrev: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    icon() {
      return {
        mdiChevronRight,
        mdiChevronLeft
      };
    }
  },
  methods: {
    prev() {
      this.$emit("prev");
    },
    next() {
      this.$emit("next");
    }
  }
});

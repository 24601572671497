
import { Repair } from "@/interfaces";

import Vue, { PropType } from "vue";

import MyOverview from "@/components/repair/MyOverview.vue";

export default Vue.extend({
  components: {
    MyOverview
  },
  props: {
    repair: {
      type: Object as PropType<Repair>,
      required: true
    }
  },
  computed: {
    state(): string {
      switch (this.repair.state) {
        case 0:
          return "未送信";
        case 1:
          return "送信済み";
        default:
          return "回答済み";
      }
    }
  }
});

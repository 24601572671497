
import Vue, { PropType } from "vue";

import { Repair } from "@/interfaces";

export default Vue.extend({
  components: {},
  props: {
    repair: {
      type: Object as PropType<Repair>,
      required: true
    }
  }
});
